<template>
  <el-dialog
    append-to-body
    :width="currType == 1 ? '650px' : '450px'"
    title=""
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
  >
    <template #title>
      <div class="el-dialog-title">{{`${currType == 1 ? '编辑' : (currType == 0 ? '添加单位' : '添加账号') }`}}</div>
    </template>

    <el-form
      v-loading="loading"
      class="saft_form"
      element-loading-text="加载中…"
      ref="elFormDom"
      label-width="130px"
      label-position="top"
      :model="form.data"
      :rules="form.rules"
      size="small"
      hide-required-asterisk
    >
      <!-- currType:0：创建单位 -->
      <template v-if="currType == 0">
        <el-form-item prop="area_arr" label="所属区域">
          <el-cascader
            :popper-append-to-body="false"
            style="width:100%;"
            popper-class="areaCascaderWrap"
            v-model="form.data.area_arr"
            :options="areaArr"
            :props="{ checkStrictly: false }"
            >
          </el-cascader>   
        </el-form-item>
        <el-form-item prop="name" label="单位名称">
          <el-input
            v-model="form.data.name"
            clearable
            placeholder="请输入单位名称"
          ></el-input>
        </el-form-item>
      </template>
      <!-- currType:1：编辑单位 -->
      <template v-if="currType == 1">
        <el-form-item prop="" label="所属区域">
          <span>{{form.data.province_str}}-{{form.data.city_str}}-{{form.data.area_str}}</span>
        </el-form-item>
        <el-form-item prop="name" label="单位名称">
          <el-input
            v-model="form.data.name"
            clearable
            placeholder="请输入单位名称"
          ></el-input>
        </el-form-item>
        <template v-if="account_list.length > 0">
          <div style="margin-bottom:10px;">账号信息</div>
          <el-row :gutter="20" v-for="item in account_list" :key="item.id">
            <el-col :span="6">
              <el-form-item prop="" label="">
                <el-input
                  v-model="item.name"
                  clearable
                  placeholder="请输入姓名"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="" label="">
                <el-input
                  v-model="item.account"
                  clearable
                  placeholder="请输入"
                  :disabled="true"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item prop="" label="">
                <el-input
                  v-model="item.password"
                  clearable
                  placeholder="修改密码"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-button type="danger" icon="el-icon-delete" circle size="mini" @click="handleDel(item.id)"></el-button>
            </el-col>
          </el-row>
        </template>
      </template>
      <!-- currType:2:创建账号 -->
      <template v-if="currType == 2">
        <el-form-item prop="title" label="姓名">
          <el-input
            v-model="form.data.title"
            clearable
            placeholder="请输入姓名"
          ></el-input>
        </el-form-item>
        <el-form-item prop="account" label="账号">
          <el-input
            v-model="form.data.account"
            maxlength="11"
            clearable
            placeholder="请输入账号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="password" label="密码">
          <el-input
            v-model="form.data.password"
            clearable
            placeholder="请输入密码"
          ></el-input>
        </el-form-item>
      </template>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click="toggle(false)">取消</el-button>
        <el-button
          type="primary"
          :loading="form.loading"
          :disabled="form.loading"
          @click="confirm"
          >确定</el-button
        >
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import { REG_EXP } from '@/util'
  export default {
    name: 'GovEdit',
    props:['areaArr'],
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            area_arr:[],
            province: '',
            city: '',
            area: '',
            government_id: '',
            title: '',
            name: '',
            account: '',
            password: '',
          },
          rules: {
            area_arr: [{required: true, message: "必填项", trigger: "change"}],
            title: [{required: true, message: "必填项", trigger: "change"}],
            name: [{required: true, message: "必填项", trigger: "change"}],
            account: [
              {required: true, message: "必填项", trigger: "change"},
              { pattern: REG_EXP.mobilePhone, message: "手机号不正确", trigger: 'blur' }
            ],
            password: [{required: true, message: "必填项", trigger: "change"}],
          }
        },
        account_list:[
          {
            name: '',
            id: '',
            account: '',
            password: '',
          }
        ],
        currType: 0,
      }
    },
    methods: {
      // 获取详情
      getDetail(row, type) {
        if(!!row.id) {
          this.isChange = true
          this.currType = type; // type, 0:创建单位，1：编辑单位，2：创建账号
          common.deepClone(this.form.data, row)
          this.account_list = [];
          if(row.account_list.length > 0) {
            row.account_list.forEach(v => {
              let str = {
                account: v.account,
                id: v.id,
                name: v.name,
                password:''
              }
              this.account_list.push(str)
            })
          }
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        this.currType = 0;
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
            let apiUrl = '';
            let _params = {}
            if(this.currType == 0) { // 创建
              apiUrl = '/admin/government/add'
              _params = {
                name: this.form.data.name, // 单位名称
                province: this.form.data.area_arr[0],
                city: this.form.data.area_arr[1],
                area: this.form.data.area_arr[2],
              }
            } else if(this.currType == 1) { // 编辑
              apiUrl = '/admin/government/edit'
              this.account_list.map(item => {
                item.account_id = item.id;
              });
              _params = {
                government_id: this.form.data.id,
                name: this.form.data.name,
                password: this.account_list.length > 0 ? JSON.stringify(this.account_list) : [],
              }
            } else { // 创建账号
              apiUrl = '/admin/government/addAccount'
              _params = {
                government_id: this.form.data.id,
                name: this.form.data.title,
                account: this.form.data.account,
                password: this.form.data.password,
              }
            }
            console.log(_params)
            this.form.loading = true;
            this.$http.post(apiUrl, _params).then(res => {
              if(res.code === 1) {
                this.toggle(false)
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
           }).finally(() => {
             this.form.loading = false;
           })
          }
        })
      },
      // 删除
      handleDel(id) {
        this.account_list.forEach((v,i) => {
          if(id == v.id) {
            this.account_list.splice(i, 1)
          }
        })
      }
    }
  }
</script>
